import React from 'react'
import { builder, BuilderComponent } from '@builder.io/react'

builder.init('a5f93be304c743a186c522e117e486db')

const MyTest = () => (
    <BuilderComponent
        model="page"
        entry="b4bd5ed32edd40cd906d9ff1723dc3d5" />
)

export default MyTest
